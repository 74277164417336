<template>
  <v-container fluid class="pa-1 blue-grey lighten-4">
    <v-card
      v-if="global.view_type == 1"
      v-resize="adjustpic1"
      class="d-flex flex-wrap justify-space-around blue-grey lighten-4"
      flat
      tile
    >
      <v-card
        v-for="people in person"
        :key="people.id"
        class="pa-0 mb-4"
        elevation="4"
        min-height="300"
      >
        <!-- @click.prevent="dblc(people.id)" -->
        <v-btn
          v-if="people.class"
          class="mt-5 ml-n4 disable-events short"
          color="primary"
          dark
          elevation="0"
          small
          fab
          top
          left
          absolute
          tile
        >
          <div class="text-body-1">
            {{ classOrRoom(people.class, people.room) }}
          </div>
        </v-btn>
        <v-btn
          @click="editFormClick1(people.id)"
          class="mb-5 mr-n4"
          fab
          dark
          x-small
          bottom
          right
          absolute
          elevation="0"
          color="primary"
          plain
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-if="people.status"
          :class="
            people.status == 'Active'
              ? 'mb-5 ml-4 disable-events'
              : 'mb-5 ml-10 disable-events'
          "
          :color="statusColor(people.status)"
          fab
          dark
          small
          bottom
          left
          absolute
          elevation="0"
          text
        >
          <v-icon left>{{ statusIcon(people.status) }}</v-icon>
          <div class="text-body-2 font-weight-bold">{{ people.status }}</div>
        </v-btn>

        <v-card-text class="pa-0">
          <v-img
            :src="`${apiurl}/images/${people.randkey}.jpg?a=${people.randomid}`"
            :width="pic_card_1_width"
          ></v-img>
        </v-card-text>

        <v-card-actions class="px-1 py-0 cartbot">
          <!-- <v-btn
            v-if="people.status"
            class="disable-events"
            :color="statusColor(people.status)"
            elevation="0"
            dark
            rounded
            small
          >
            <v-icon left>{{ statusIcon(people.status) }}</v-icon>
            <div class="text-body-1">{{ people.status }}</div>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-card>
    <v-card
      v-if="global.view_type == 2"
      v-resize="adjustcard2"
      class="d-flex flex-wrap justify-space-around blue-grey lighten-4"
      flat
      tile
    >
      <v-card
        v-for="people in person"
        :key="people.id"
        class="pa-0 mb-4"
        min-height="250"
        outlined
        :width="card_2_width"
      >
        <v-btn
          v-if="people.class"
          class="mt-5 ml-n4 disable-events short"
          color="primary"
          dark
          elevation="0"
          small
          fab
          top
          left
          absolute
          tile
        >
          <div class="text-body-1">
            {{ classOrRoom(people.class, people.room) }}
          </div>
        </v-btn>

        <v-btn
          @click="editFormClick1(people.id)"
          class="mb-5 mr-n4"
          fab
          dark
          x-small
          bottom
          right
          absolute
          elevation="0"
          color="primary"
          plain
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-if="people.status"
          :class="
            people.status == 'Active'
              ? 'mb-5 ml-4 disable-events'
              : 'mb-5 ml-10 disable-events'
          "
          :color="statusColor(people.status)"
          fab
          dark
          small
          bottom
          left
          absolute
          elevation="0"
          text
        >
          <v-icon left>{{ statusIcon(people.status) }}</v-icon>
          <div class="text-body-2 font-weight-bold">{{ people.status }}</div>
        </v-btn>

        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col cols="6" class="pa-0">
              <v-img
                :src="`${apiurl}/images/${people.randkey}.jpg?a=${people.randomid}`"
                :width="pic_card_2_width"
                class="mb-10"
              ></v-img>
            </v-col>
            <v-col cols="6" class="pa-3">
              <!-- <h4>id : {{ people.id }}</h4> -->
              <div class="text-h5 mb-2">{{ people.jsjid }}</div>
              <!-- <h1>{{ people.jsjid }}</h1> -->
              <h5>{{ people.fname }} {{ people.lname }}</h5>
              <h5 v-if="people.province">{{ people.province }}</h5>
              <h5 v-if="people.occupation">{{ people.occupation }}</h5>
              <h4 v-if="people.phone_number">
                โทร : {{ people.phone_number }}
              </h4>
              <h4 v-if="people.line">ไลน์ : {{ people.line }}</h4>

              <!-- <h4 v-if="people.year_of_birth">
                ปีเกิด : {{ people.year_of_birth }}
              </h4> -->
              <!-- <h4 v-if="people.know_from">
                รู้จักมูลนิธิจาก : {{ people.know_from }}
              </h4>
              <h4 v-if="people.teacher1">1.อาจารย์ {{ people.teacher1 }}</h4>
              <h4 v-if="people.teacher2">2.อาจารย์ {{ people.teacher2 }}</h4> -->
              <h4 v-if="people.remark">โน้ต :</h4>
              <h3 v-if="people.remark">{{ people.remark }}</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions class="px-1 py-0 cartbot">
          <v-btn
            v-if="people.status"
            class="disable-events"
            :color="statusColor(people.status)"
            elevation="0"
            dark
            rounded
            small
          >
            <v-icon left>{{ statusIcon(people.status) }}</v-icon>
            <div class="text-body-1">{{ people.status }}</div>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-card>
    <v-data-table
      v-resize="onResize"
      v-else-if="global.view_type == 3"
      :headers="selected_column"
      :items="person"
      class="elevation-1"
      :hide-default-footer="true"
      disable-pagination
      fixed-header
      :height="windowSize.y - 100"
      dense
      @dblclick:row="dbl"
      :mobile-breakpoint="0"
    >
      <template v-slot:item.status="{ item }">
        <v-chip v-if="item.status" small :color="statusColor(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:item.room1="{ item }">
        {{ datethai(item.room1) }}
      </template>
      <template v-slot:item.room2="{ item }">
        {{ datethai(item.room2) }}
      </template>
      <template v-slot:item.room3="{ item }">
        {{ datethai(item.room3) }}
      </template>
      <template v-slot:item.room4="{ item }">
        {{ datethai(item.room4) }}
      </template>
      <template v-slot:item.room5="{ item }">
        {{ datethai(item.room5) }}
      </template>
      <template v-slot:item.room6="{ item }">
        {{ datethai(item.room6) }}
      </template>
      <template v-slot:item.room7="{ item }">
        {{ datethai(item.room7) }}
      </template>
      <template v-slot:item.room8="{ item }">
        {{ datethai(item.room8) }}
      </template>
      <template v-slot:item.room9="{ item }">
        {{ datethai(item.room9) }}
      </template>
      <template v-slot:item.room10="{ item }">
        {{ datethai(item.room10) }}
      </template>
    </v-data-table>
    <editFormPerson ref="editFormPerson" />
    <!-- <v-row v-if="$vuetify.breakpoint.xs">
      <v-col> -->
    <!-- <div v-if="$vuetify.breakpoint.xs" class="text-center mb-2">
      <v-pagination
        v-model="active_page"
        :length="global.total_page"
      ></v-pagination>
    </div> -->
    <!-- </v-col>
    </v-row> -->

    <!-- <v-footer
      v-if="!$vuetify.breakpoint.xs"
      app
      padless
      inset
      style="z-index: 4"
    >
      <v-row no-gutters>
        <v-col cols="12" sm="5" md="5" class="blue-grey lighten-5">
          <v-card elevation="0" max-width="800">
            <v-tabs
              background-color="blue-grey lighten-5"
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              show-arrows
              height="42"
              center-active
              centered
              active-class="activetab"
              v-model="active_tab"
              hide-slider
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab v-for="(tab, index) of user_info.room" :key="index">
                {{ tab }}
              </v-tab>
            </v-tabs>
          </v-card>
        </v-col>

        <v-col
          cols="2"
          class="blue-grey lighten-5"
          v-if="!$vuetify.breakpoint.xs"
        >
          <v-card tile elevation="0">
            <v-card-text class="text-center pa-1 mt-2">
              <h3>ผลการค้นหา {{ global.total_record }} รายการ</h3>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          class="text-center blue-grey lighten-5"
          cols="12"
          sm="12"
          md="12"
        >
          <v-pagination
            v-model="active_page"
            :length="global.total_page"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-footer> -->
    <!-- <v-footer v-else app padless inset style="z-index: 4">
      <v-tabs
        background-color="blue-grey lighten-5"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        height="42"
        center-active
        centered
        active-class="activetab"
        v-model="active_tab"
        hide-slider
      >
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab v-for="(tab, index) of user_info.room" :key="index">
          {{ tab }}
        </v-tab>
      </v-tabs>
    </v-footer> -->

    <v-footer
      v-if="!$store.state.global.loading"
      app
      padless
      inset
      style="z-index: 4"
    >
      <!-- <v-row no-gutters>
        <v-col class="text-center blue-grey lighten-5" cols="12"> -->
      <v-container class="max-width pa-0">
        <v-pagination
          v-model="$store.state.current_page"
          :length="global.total_page"
        ></v-pagination>
      </v-container>
      <!-- </v-col>
      </v-row> -->
    </v-footer>
  </v-container>
</template>

<script>
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
};

import { mapState } from "vuex";
import editFormPerson from "@/components/editFormPerson";
export default {
  name: "person",
  components: {
    editFormPerson,
  },
  data: () => ({
    duplicateRoom: [],
    selected_column: [
      {
        text: "รหัส",
        align: "start",
        sortable: false,
        value: "jsjid",
      },
      { text: "คำนำ", value: "pname", align: "start", sortable: false },
      { text: "ชื่อ", value: "fname", align: "start", sortable: false },
      { text: "สกุล", value: "lname", align: "start", sortable: false },
      {
        text: "ปีเกิด",
        value: "year_of_birth",
        align: "start",
        sortable: false,
      },
      { text: "จังหวัด", value: "province", align: "start", sortable: false },
      {
        text: "อาชีพ",
        value: "occupation",
        align: "start",
        sortable: false,
      },
      {
        text: "หมายเลขโทรศัพท์",
        value: "phone_number",
        align: "start",
        sortable: false,
      },
      { text: "ไลน์", value: "line", align: "start", sortable: false },
      {
        text: "รู้จักมูลนิธิจาก",
        value: "know_from",
        align: "start",
        sortable: false,
      },
      {
        text: "หลักสูตร",
        value: "class",
        align: "end",
        sortable: false,
      },
      {
        text: "ห้อง",
        value: "room",
        align: "start",
        sortable: false,
      },
      { text: "อาจารย์ 1", value: "teacher1", align: "start", sortable: false },
      { text: "อาจารย์ 1", value: "teacher2", align: "start", sortable: false },
      { text: "สถานะ", value: "status", align: "start", sortable: false },
      { text: "โน้ต", value: "remark", align: "start", sortable: false },
      { text: "เข้ามูลนิธิ", value: "room1", align: "start", sortable: false },
      { text: "หลักสูตร 2", value: "room2", align: "start", sortable: false },
      { text: "หลักสูตร 3", value: "room3", align: "start", sortable: false },
      { text: "หลักสูตร 4", value: "room4", align: "start", sortable: false },
      { text: "หลักสูตร 5", value: "room5", align: "start", sortable: false },
      { text: "หลักสูตร 6", value: "room6", align: "start", sortable: false },
      { text: "หลักสูตร 7", value: "room7", align: "start", sortable: false },
      { text: "หลักสูตร 7.10", value: "room71", align: "start", sortable: false },
      { text: "หลักสูตร 8", value: "room8", align: "start", sortable: false },
      { text: "หลักสูตร 9", value: "room9", align: "start", sortable: false },
      { text: "หลักสูตร 10", value: "room10", align: "start", sortable: false },
    ],
    active_tab: null,
    // active_page: 1,
    windowSize: {
      x: 0,
      y: 0,
    },
    delay: 300,
    counter: 0,
    timer: null,
    pic_card_1_width: 0,
    pic_card_2_width: 0,
    card_2_width: 0,
  }),
  methods: {
    classOrRoom(classroom, room) {
      let re = "";
      // var index = this.duplicateRoom.findIndex(
      //   (element) => element == classroom
      // );
      // if (index != -1) {
      //   re = `${classroom}/${room}`;
      // } else {
      //   re = `${classroom}`;
      // }
      if (room == null) {
        if (classroom == "Mentor") {
          re = "M";
        } else {
          re = classroom;
        }
      } else {
        re = classroom + "/" + room;
      }
      return re;
    },
    // ininRoomSplit() {
    //   let arrroom = [];
    //   for (const room of this.$store.state.user_info.room) {
    //     const roomsplit = room.split("/");
    //     arrroom.push(roomsplit[0]);
    //   }
    //   const tofindDuplicates = (arrroom) =>
    //     arrroom.filter((item, index) => arrroom.indexOf(item) !== index);
    //   const duplicateElementa = tofindDuplicates(arrroom);
    //   // console.log(duplicateElementa);
    //   this.duplicateRoom = duplicateElementa;
    // },
    dblc(e) {
      this.counter++;
      if (this.counter == 1) {
        this.timer = setTimeout(() => {
          this.counter = 0;
        }, 300);
        return;
      }

      clearTimeout(this.timer);
      this.counter = 0;
      // this.likeThePost(postId);
      alert(e);
    },
    datethai(input) {
      var re = "";
      if (input) {
        const event = new Date(input);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        re = event.toLocaleDateString("th-TH", options);
      }
      return re;
    },
    statusColor(status) {
      var re = "";
      if (status == "Active") {
        re = "green darken-1";
      } else if (status == "รอรายงานตัว") {
        re = "orange darken-2";
      } else if (status == "Nonactive") {
        re = "grey darken-1";
      } else {
        re = "black";
      }
      return re;
    },
    statusIcon(status) {
      var re = "";
      if (status == "Active") {
        re = "mdi-checkbox-marked-circle";
      } else if (status == "รอรายงานตัว") {
        re = "mdi-clock-fast";
      } else if (status == "Nonactive") {
        re = "mdi-cancel";
      } else {
        re = "mdi-close-circle";
      }
      return re;
    },
    // async change_room(e) {
    //   this.global.current_room = await this.user_info.room[e];
    //   if (this.global.loading != true) {
    //     await this.$store.dispatch("fetch_person");
    //   }
    //   this.$store.state.query_option.current_page = await 1;
    //   this.active_page = await 1;
    // },
    async change_page(e) {
      console.log("CCC");
      this.$store.state.current_page = e;
      if (this.global.loading != true) {
        await this.$store.dispatch("fetch_person");
        await window.scrollTo(0, 0);
      }
    },
    dbl: function (item, row) {
      this.do_edit_form(row.item.id);
    },
    async editFormClick1(id) {
      this.do_edit_form(id);
    },
    async do_edit_form(id) {
      // if (
      //   this.user_info.role == "1" ||
      //   this.user_info.role == "2" ||
      //   this.user_info.role == "3"
      // ) {
      await this.$refs.editFormPerson.init();
      this.$refs.editFormPerson.mode = await "edit";
      this.$refs.editFormPerson.dialog = await true;
      this.$refs.editFormPerson.editId = await id;
      await this.$refs.editFormPerson.formInitEdit();
      // }
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    // async room_access() {
    //   let { data } = await axios.get(
    //     `${this.$store.state.apiurl}/api/roomaccess`
    //   );
    //   this.$store.state.user_info.room = await data.room;
    //   this.$store.state.current_room = await data.room[0];
    // },
    async init() {
      this.$store.state.query_option.keyword_search = "";
      this.global.view_type =
        JSON.parse(localStorage.getItem("view_type")) || 1;
      // this.$store.dispatch("init_class_room_full_val", { type: 1 });
      // this.$store.dispatch("init_class_room_full_key", { type: 1 });
      // this.$store.dispatch("init_class_room_full", { type: 1 });
      // this.$store.dispatch("init_status_full", { type: 2 });

      const { data } = await axios.get(
        `${this.$store.state.apiurl}/api/listforselect`,
        {
          params: { type: 2 },
        }
      );
      this.$store.state.global.status_full = data;

      // if (localStorage.getItem("line_tick") === "false") {
      //   this.$store.state.query_option.line_tick = false;
      // } else {
      //   this.$store.state.query_option.line_tick = true;
      // }
      // if (localStorage.getItem("no_line_tick") === "false") {
      //   this.$store.state.query_option.no_line_tick = false;
      // } else {
      //   this.$store.state.query_option.no_line_tick = true;
      // }
    },
    adjustpic1() {
      // console.log(window.innerWidth);
      //   if (this.$vuetify.breakpoint.xs) {
      //     this.pic_card_1_width = window.innerWidth - 10;
      //   } else if (this.$vuetify.breakpoint.sm) {
      //     // this.pic_card_1_width =
      //     //   (window.innerWidth / 3 - 10) > 200 ? window.innerWidth / 3 - 50 : 200;
      //     this.pic_card_1_width = window.innerWidth / 3 - 10;
      //   } else if (this.$vuetify.breakpoint.md) {
      //     this.pic_card_1_width = window.innerWidth / 4 - 10;
      //   } else if (this.$vuetify.breakpoint.lg) {
      //     this.pic_card_1_width = window.innerWidth / 6 - 10;
      //   } else if (this.$vuetify.breakpoint.xl) {
      //     this.pic_card_1_width = window.innerWidth / 8 - 10;
      //   } else {
      //     this.pic_card_1_width = 200;
      //   }
      let basesize = 200;
      let perrow = window.innerWidth / basesize;
      this.pic_card_1_width = window.innerWidth / perrow - 10;
    },
    adjustcard2() {
      if (window.innerWidth < 600) {
        this.card_2_width = window.innerWidth - 10;
        this.pic_card_2_width = window.innerWidth / 2 - 10;
      } else if (window.innerWidth < 900) {
        let basesize = 280;
        let perrow = window.innerWidth / basesize;
        this.card_2_width = window.innerWidth / perrow;
        this.pic_card_2_width = window.innerWidth / perrow / 2;
      } else if (window.innerWidth < 1300) {
        let basesize = 300;
        let perrow = window.innerWidth / basesize;
        this.card_2_width = window.innerWidth / perrow;
        this.pic_card_2_width = window.innerWidth / perrow / 2;
      } else if (window.innerWidth < 1900) {
        let basesize = 350;
        let perrow = window.innerWidth / basesize;
        this.card_2_width = window.innerWidth / perrow;
        this.pic_card_2_width = window.innerWidth / perrow / 2;
      } else {
        let basesize = 400;
        let perrow = window.innerWidth / basesize;
        this.card_2_width = window.innerWidth / perrow;
        this.pic_card_2_width = window.innerWidth / perrow / 2;
      }
    },
  },
  computed: {
    ...mapState(["person", "global", "user_info"]),
    apiurl() {
      return this.$store.state.apiurl;
    },
    // image_host: function () {
    //   return this.$store.getters.get_image_host;
    // },
    // query_option: {
    //   get() {
    //     return this.$store.getters.get_query_option;
    //   },
    //   set(value) {
    //     this.$store.dispatch("handle_query_option", {
    //       query_option: value,
    //     });
    //   },
    // },
    global: {
      get() {
        return this.$store.getters.get_global;
      },
      set(value) {
        this.$store.dispatch("handle_global", {
          global: value,
        });
      },
    },
  },
  watch: {
    "$store.state.current_page": {
      handler() {
        if (this.global.loading != true) {
          this.$store.dispatch("fetch_person");
        }
      },
      deep: true,
    },

    // active_tab: {
    //   handler() {
    //     this.change_room(this.active_tab);
    //   },
    //   deep: true,
    // },
    // active_page: {
    //   handler() {
    //     this.change_page(this.active_page);
    //   },
    //   deep: true,
    // },
  },
  async mounted() {
    this.$store.state.current_page = await 1;
    this.$store.state.current_room = await this.$store.state.user_info.room[0];
    this.adjustpic1();
    this.adjustcard2();
    // this.ininRoomSplit();
    this.$store.state.person = await [];
    this.$store.state.global.loading = await true;
    // await this.room_access();
    await this.init();
    this.$store.state.query_option.status_tick =
      (await JSON.parse(localStorage.getItem("status_tick"))) ||
      this.$store.state.global.status_full;
    // await console.log(this.$store.state.global.status_full);
    await this.$store.dispatch("fetch_person");
    this.$store.state.global.loading = await false;
    // await this.$store.dispatch("fetch_countpeopledb");
    // console.log(this.$store.state.people_amount);
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
}
table {
  white-space: nowrap;
}

table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}
.picheight {
  height: 295px;
}

.short {
  border-radius: 2px;
}
.long {
  border-radius: 2px;
  width: 50px;
}

.cardpic {
  height: 310px;
  background-color: rgb(255, 255, 255);
}

.cartbot {
  height: 38px;
  background-color: rgb(255, 255, 255);
}
.activetab {
  color: #ffffff !important;
  background-color: #2196f3 !important;
}
</style>
