var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-1 blue-grey lighten-4",attrs:{"fluid":""}},[(_vm.global.view_type == 1)?_c('v-card',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.adjustpic1),expression:"adjustpic1"}],staticClass:"d-flex flex-wrap justify-space-around blue-grey lighten-4",attrs:{"flat":"","tile":""}},_vm._l((_vm.person),function(people){return _c('v-card',{key:people.id,staticClass:"pa-0 mb-4",attrs:{"elevation":"4","min-height":"300"}},[(people.class)?_c('v-btn',{staticClass:"mt-5 ml-n4 disable-events short",attrs:{"color":"primary","dark":"","elevation":"0","small":"","fab":"","top":"","left":"","absolute":"","tile":""}},[_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.classOrRoom(people.class, people.room))+" ")])]):_vm._e(),_c('v-btn',{staticClass:"mb-5 mr-n4",attrs:{"fab":"","dark":"","x-small":"","bottom":"","right":"","absolute":"","elevation":"0","color":"primary","plain":""},on:{"click":function($event){return _vm.editFormClick1(people.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(people.status)?_c('v-btn',{class:people.status == 'Active'
            ? 'mb-5 ml-4 disable-events'
            : 'mb-5 ml-10 disable-events',attrs:{"color":_vm.statusColor(people.status),"fab":"","dark":"","small":"","bottom":"","left":"","absolute":"","elevation":"0","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.statusIcon(people.status)))]),_c('div',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(_vm._s(people.status))])],1):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('v-img',{attrs:{"src":(_vm.apiurl + "/images/" + (people.randkey) + ".jpg?a=" + (people.randomid)),"width":_vm.pic_card_1_width}})],1),_c('v-card-actions',{staticClass:"px-1 py-0 cartbot"})],1)}),1):_vm._e(),(_vm.global.view_type == 2)?_c('v-card',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.adjustcard2),expression:"adjustcard2"}],staticClass:"d-flex flex-wrap justify-space-around blue-grey lighten-4",attrs:{"flat":"","tile":""}},_vm._l((_vm.person),function(people){return _c('v-card',{key:people.id,staticClass:"pa-0 mb-4",attrs:{"min-height":"250","outlined":"","width":_vm.card_2_width}},[(people.class)?_c('v-btn',{staticClass:"mt-5 ml-n4 disable-events short",attrs:{"color":"primary","dark":"","elevation":"0","small":"","fab":"","top":"","left":"","absolute":"","tile":""}},[_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.classOrRoom(people.class, people.room))+" ")])]):_vm._e(),_c('v-btn',{staticClass:"mb-5 mr-n4",attrs:{"fab":"","dark":"","x-small":"","bottom":"","right":"","absolute":"","elevation":"0","color":"primary","plain":""},on:{"click":function($event){return _vm.editFormClick1(people.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(people.status)?_c('v-btn',{class:people.status == 'Active'
            ? 'mb-5 ml-4 disable-events'
            : 'mb-5 ml-10 disable-events',attrs:{"color":_vm.statusColor(people.status),"fab":"","dark":"","small":"","bottom":"","left":"","absolute":"","elevation":"0","text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.statusIcon(people.status)))]),_c('div',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(_vm._s(people.status))])],1):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-img',{staticClass:"mb-10",attrs:{"src":(_vm.apiurl + "/images/" + (people.randkey) + ".jpg?a=" + (people.randomid)),"width":_vm.pic_card_2_width}})],1),_c('v-col',{staticClass:"pa-3",attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h5 mb-2"},[_vm._v(_vm._s(people.jsjid))]),_c('h5',[_vm._v(_vm._s(people.fname)+" "+_vm._s(people.lname))]),(people.province)?_c('h5',[_vm._v(_vm._s(people.province))]):_vm._e(),(people.occupation)?_c('h5',[_vm._v(_vm._s(people.occupation))]):_vm._e(),(people.phone_number)?_c('h4',[_vm._v(" โทร : "+_vm._s(people.phone_number)+" ")]):_vm._e(),(people.line)?_c('h4',[_vm._v("ไลน์ : "+_vm._s(people.line))]):_vm._e(),(people.remark)?_c('h4',[_vm._v("โน้ต :")]):_vm._e(),(people.remark)?_c('h3',[_vm._v(_vm._s(people.remark))]):_vm._e()])],1)],1)],1)}),1):(_vm.global.view_type == 3)?_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"elevation-1",attrs:{"headers":_vm.selected_column,"items":_vm.person,"hide-default-footer":true,"disable-pagination":"","fixed-header":"","height":_vm.windowSize.y - 100,"dense":"","mobile-breakpoint":0},on:{"dblclick:row":_vm.dbl},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"small":"","color":_vm.statusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()]}},{key:"item.room1",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room1))+" ")]}},{key:"item.room2",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room2))+" ")]}},{key:"item.room3",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room3))+" ")]}},{key:"item.room4",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room4))+" ")]}},{key:"item.room5",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room5))+" ")]}},{key:"item.room6",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room6))+" ")]}},{key:"item.room7",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room7))+" ")]}},{key:"item.room8",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room8))+" ")]}},{key:"item.room9",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room9))+" ")]}},{key:"item.room10",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datethai(item.room10))+" ")]}}])}):_vm._e(),_c('editFormPerson',{ref:"editFormPerson"}),(!_vm.$store.state.global.loading)?_c('v-footer',{staticStyle:{"z-index":"4"},attrs:{"app":"","padless":"","inset":""}},[_c('v-container',{staticClass:"max-width pa-0"},[_c('v-pagination',{attrs:{"length":_vm.global.total_page},model:{value:(_vm.$store.state.current_page),callback:function ($$v) {_vm.$set(_vm.$store.state, "current_page", $$v)},expression:"$store.state.current_page"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }